
import React from 'react'
import Carousel from '../Carousal/Carousel'
import Truckthree from '../../images/trailers/istockphoto-1289928297-612x612.jpg'
import { Link } from 'react-router-dom';
import PriceCard from '../Cards/PriceCard';
import Table from '../Table/Table';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import tridum from '../../images/tridem/DSC_0083.jpg'
import tridumOne from '../../images/tridem/DSC_0084.jpg'
import tridumTwo from '../../images/tridem/DSC_0085.jpg'
const SinglePage = () => {
  
const contentStyle = {
  height: '500px',
  lineHeight: '160px',
  textAlign: 'center',

};

const imageStyle = {
  objectFit: 'cover',
  width: '100%',
};

const columnsPage2 = [
    {
      title: 'Measurement',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Inch',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Payload',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  
  const dataPage2 = [
    {
      key: '1',
      name: 'Total Height (rear gate)',
      age: 70,
      address: '20000 Pound',
    },
    {
      key: '2',
      name: 'Load Capacity',
      age: 32,
    },
    {
      key: '3',
      name: 'Total Length',
      age: 252,
    },
    {
      key: '4',
      name: 'Box Length',
      age: 210,
    },
   
  ];
  return (
    <div>
        <h1>Single Page</h1>
        <div>
        <MDBCarousel showIndicators showControls fade>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={1}
          src={tridum}
          alt='...'
        >
          <h5>16 ft Dump Trailer</h5>
          
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={2}
          src={tridumTwo}
          alt='...'
        >
          <h5>16 ft Dump Trailer</h5>
          
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={3}
          src={tridumOne}
          alt='...'
        >
          <h5>16 ft Dump Trailer</h5>
          
        </MDBCarouselItem>
          </MDBCarousel>



          

          
    </div>

          
        <div className='headerDiv'>

        <div className='home-heading'>
          <p>RENT TRAILERS
          </p>
          <h1>About The Trailer</h1>
          <p>Introducing our 16 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability. With its tandem wheel design, this trailer offers enhanced stability and a remarkable load-bearing capacity of up to 20,000 pounds. Rent it now and take your project to the next level! Contact us at 778-384-3377 or sgequiprental@gmail.com.</p>

          <div className='buttons'>
            <button className='buttonsTwo'>Check Price</button>
          </div>




        </div>

        <div>
          <div className='imageDiv'>
            <img src={Truckthree} style={{ ...contentStyle, ...imageStyle }}></img>
          </div>
        </div>
      </div>

      

      <div className='price_cards_div'>
        <div className="price_card alpha">
          <div className="header">
        <span className="price">$120</span>
        <span className="name">1 DAY</span>
          </div>
          <ul className="features">
        <li>16 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>

        <div className="price_card bravo">
          <div className="header">
        <span className="price">$300</span>
        <span className="name">3 DAY</span>
          </div>
          <ul className="features">
        <li>16 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>

        <div className="price_card charlie">
          <div className="header">
        <span className="price">$470</span>
        <span className="name">5 DAYS</span>
          </div>
          <ul className="features">
        <li>16 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>
        <div className="price_card bravo">
          <div className="header">
        <span className="price">$570</span>
        <span className="name">7 DAYS</span>
          </div>
          <ul className="features">
        <li>16 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>
        {/* <div className="price_card alpha">
          <div className="header">
        <span className="price">$625</span>
        <span className="name">5 DAYS</span>
          </div>
          <ul className="features">
        <li>14 ft Dump Traile</li>
        <li>Working materials in PSD and Sketch format</li>
          </ul>
        </div> */}
    </div>

      <br />
      <br />
<hr />
<br />
      <Table columns={columnsPage2} data={dataPage2}/>

      <br />
      <br />



    </div>

    
  )
}

export default SinglePage