import React from 'react';
import Cards from './Cards';
import './home.css';
import { Carousel } from 'antd';
import Map from '../images/trailers/pexels-andrew-neel-2859169.jpg'
import Truckthree from './../images/trailers/pexels-islandhopper-x-17816414.jpg'
import CardTwo from './../component/Cards/CardTwo'
import GoogleMapReact from 'google-map-react';
import { Link } from 'react-router-dom';
import Gallery from './Gallery/Gallery'
// import Trailer from './../images/Trailer-Pics/DSC_0076.jpg'
import Trailer from '../images/Trailer-Pics/DSC_0076.jpg'
import trailerTwo from '../images/Trailer-Pics/DSC_0077.jpg'
import trailerThree from '../images/Trailer-Pics/DSC_0078.jpg'
import tridum from './../images/Trailer-Pics/tridum.jpeg'
import tridumOne from '../images/tridem/DSC_0093.jpg'
import RAM from '../images/tridem/RAM.jpeg'





const AnyReactComponent = ({ text }) => <div>{text}</div>;

const contentStyle = {
  height: '500px',
  lineHeight: '160px',
  textAlign: 'center',

};

const imageStyle = {
  objectFit: 'cover',
  width: '100%',
};


const imageStyleTwo = {
  height: '700px',
  objectFit: 'cover',
  width: '100%',
};



const Home = () => {

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };






  return (
    <div>


      <div className='textOverImage'>
        <img src={tridumOne} style={{ ...imageStyleTwo, ...imageStyle }} alt="" />
        <div className='centered'>
          <h2>SNG Equipment Rental LTD</h2>
          <p>Introduction:
            SNG Equipment Rental is a leading company that specializes in providing high-quality trailer rental services to cater to a wide range of transportation needs. With a strong commitment to customer satisfaction and a fleet of well-maintained trailers, we have established ourselves as a reliable and trusted partner for individuals and businesses seeking cost-effective and efficient trailer solutions.</p>
            <Link to='/trailers'><button className='buttonsthree'>Rent a Trailer</button></Link>
        </div>
      </div>

      <div className='headerDiv'>

        <div className='home-heading'>
          <p>RENT TRAILERS
          </p>
          <h1>Keep your trailers on the move</h1>
          <p>Your trusted destination for utility dump trailers! 🚚 We offer a wide range of trailer options for your project needs, including dump trailers, enclosed trailers, flatbed trailers, and more. 🛠️ Choose from daily, weekly, or monthly rentals, with pick-up and delivery available for an extra charge. Contact us today for the perfect trailer solution! 📞 778-384-3377 📧 sgequiprental@gmail.com</p>

          <div className='buttons'>
            <Link to='/trailers'><button className='buttonsOne'>Rent a Trailer</button></Link>
            <Link to='/trailers'><button className='buttonsTwo'>Check Price</button></Link>
          </div>




        </div>

        <div>
          <div className='imageDiv'>
            <img src={RAM} style={{ ...contentStyle, ...imageStyle }}></img>
          </div>
        </div>
      </div>



      <div className='essentialHeading'>
        <div>
          <h2>
            Why choose ?
          </h2>
          <h2><span className='essentialSpan'>SNG Equipment Rental for Trailer Rentals</span></h2>

        </div>

        <div className='essentialDescription'>
          <div>
            <h3>Diverse Selection:</h3>
            <p>We offer a wide range of trailers, from utility trailers to specialized options, ensuring we have the perfect trailer for your specific needs.</p>
          </div>
          <div>
            <h3>Well-Maintained Equipment:</h3>
            <p>Our trailers are meticulously maintained and undergo regular inspections to ensure they are in excellent working condition.</p>
          </div>
          <div>
            <h3>Competitive Rates: </h3>
            <p> We provide affordable rental options and competitive pricing, making trailer rentals accessible for projects of any size.</p>
          </div>
          <div>
            <h3>Safety is Paramount:</h3>
            <p>Safety is our top priority. Our trailers are maintained to the highest safety standards, ensuring peace of mind for your team.</p>
          </div>
        </div>
      </div>

      <div className='we-provide'>
        <h2>We Provide These Trailer</h2>
      </div>
      <div className='cards-home'>
        
        <CardTwo
          image={Trailer}
          title="14 ft Dump Trailer"
          description="Introducing our 14 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
        />
        <CardTwo
          image={tridum}
          title="16 ft Dump Trailer"
          description="Introducing our 16 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
        />
        <CardTwo
          image={tridumOne}
          title="16 ft Dump Trailer"
          description="Introducing our 16 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
        />
         <CardTwo
          image={RAM}
          title="RAM 5500"
          description="Looking for a heavy-duty workhorse to tackle your daily hauling and towing needs? Look no further! We offer the versatile and powerful RAM 5500 for rent on a daily basis. Whether you're a contractor, in the construction business, or need a reliable vehicle for a special project, the RAM 5500 has you covered."
        />

      </div>

      <div id='gallery' className='gallery'>
        <h2>Gallery</h2>
        <br />
        <Gallery />
      </div>


      <div className='map-class'>
        <div>
          <h2>Our Location</h2>
          <p>Click here to redirect to map</p>
        </div>
        <div style={{ height: '350px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={49.138560}
              lng={-122.915960}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>

    </div>
  );
};

export default Home;
