
import React from 'react'
import Carousel from '../Carousal/Carousel'
import Truckthree from '../../images/trailers/istockphoto-1289928297-612x612.jpg'
import { Link } from 'react-router-dom';
import PriceCard from '../Cards/PriceCard';
import Table from '../Table/Table';
import trailerTwo from '../../images/Trailer-Pics/DSC_0077.jpg'
import trailerThree from '../../images/Trailer-Pics/DSC_0078.jpg'
import trailer from '../../images/Trailer-Pics/DSC_0079.jpg'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import RAM from '../../images/tridem/RAM.jpeg'
const SinglePageRam = () => {
  
const contentStyle = {
  height: '500px',
  lineHeight: '160px',
  textAlign: 'center',

};

const imageStyle = {
  objectFit: 'cover',
  width: '100%',
};

const columnsPage1 = [
    {
      title: 'Measurement',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Inch',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Payload',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  
  const dataPage1 = [
    {
      key: '1',
      name: 'Total Height (rear gate)',
      age: 60,
      address: '15000 Pound',
    },
    {
      key: '2',
      name: 'Box height',
      age: 60,
    },
    {
      key: '3',
      name: 'Total Length',
      age: 204,
    },
    {
      key: '4',
      name: 'Box Length',
      age: 192,
    },
   
  ];

  return (
    <div>
        <h1>Single Page</h1>
        <div>
        <MDBCarousel showIndicators showControls fade>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={1}
          src={RAM}
          alt='...'
        >
          <h5>RAM</h5>
          
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={2}
          src={trailer}
          alt='...'
        >
          <h5>RAM</h5>
          
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={3}
          src={trailerTwo}
          alt='...'
        >
          <h5>RAM</h5>
          
        </MDBCarouselItem>
          </MDBCarousel>



          

          
    </div>

          
        <div className='headerDiv'>

        <div className='home-heading'>
          <p>RENT TRAILERS
          </p>
          <h1>About The Truck</h1>
          <p>Looking for a heavy-duty workhorse to tackle your daily hauling and towing needs? Look no further! We offer the versatile and powerful RAM 5500 for rent on a daily basis. Whether you're a contractor, in the construction business, or need a reliable vehicle for a special project, the RAM 5500 has you covered.</p>

          <div className='buttons'>
            <button className='buttonsTwo'>Check Price</button>
          </div>




        </div>

        <div>
          <div className='imageDiv'>
            <img src={RAM} style={{ ...contentStyle, ...imageStyle }}></img>
          </div>
        </div>
      </div>

      

      <div className='price_cards_div'>
        <div className="price_card alpha">
          <div className="header">
        <span className="price">$250</span>
        <span className="name">1 DAY</span>
          </div>
          <ul className="features">
        <li>RAM</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>

        <div className="price_card bravo">
          <div className="header">
        <span className="price">$730</span>
        <span className="name">3 DAY</span>
          </div>
          <ul className="features">
        <li>RAM</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>

        <div className="price_card charlie">
          <div className="header">
        <span className="price">$1230</span>
        <span className="name">5 DAYS</span>
          </div>
          <ul className="features">
        <li>RAM</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>
        
     
    </div>

      <br />
      <br />
<hr />
<br />
      {/* <Table columns={columnsPage1} data={dataPage1}/> */}

      <br />
      <br />



    </div>

    
  )
}

export default SinglePageRam