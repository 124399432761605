import React from 'react'
// import Cards from './Cards'
import './trailers.css'
import tridum from './../images/Trailer-Pics/tridum.jpeg'
import CardTwo from './../component/Cards/CardTwo'
import Trailer from '../images/Trailer-Pics/DSC_0076.jpg'
import { Link } from 'react-router-dom'
import tridumTwo from './../images/Trailer-Pics/tridumTwo.jpeg'
import RAM from '../images/tridem/RAM.jpeg'
import TridumOne from '../images/tridem/DSC_0083.jpg'
import Tridumtwo from '../images/tridem/DSC_0084.jpg'


const Trailers = () => {
  return (
    <div>
        <div className='trailer-heading'>
            <h1>TRAILERS</h1>

            {/* <div className='cards-trailers'> */}
              {/* <Cards/>
              <Cards/>
              <Cards/>
              <Cards/>
              <Cards/>
              <Cards/>
              <Cards/>
              <Cards/>
              <Cards/> */}
              <div className='cards-home'>
        <Link to="/singlePage">
          <CardTwo
            image={Trailer}
            title="14 ft Dump Trailer"
            description="Introducing our 14 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
          />
        </Link>

        <Link to="/singlePageTridum">
        <CardTwo
          image={TridumOne}
          title="16 ft Dump Trailer"
          description="Introducing our 16 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
        />
        </Link>
        <Link to="/singlePageRam">
        <CardTwo
          image={RAM}
          title="RAM 5500"
          description="Are you in need of powerful and reliable RAM heavy-duty equipment for your next project? Look no further! We offer top-of-the-line, high-capacity RAM modules and servers that can handle even the most demanding tasks with ease."
        />
        </Link>
        <Link to="/singlePageTridum">
          <CardTwo
            image={Tridumtwo}
            title="16 ft Dump Trailer"
            description="Introducing our 16 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
          />
        </Link>

        <Link to="/singlePageTridum">
        <CardTwo
          image={tridum}
          title="16 ft Dump Trailer"
          description="Introducing our 16 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability."
        />
        </Link>
        {/* <Link to="/singlePageTridum">
        <CardTwo
          image={tridumTwo}
          title="Customized Trailer Title 3"
          description="Customized paragraph for the third card. Describe the features and benefits of this trailer."
        />
        </Link> */}

      </div>
            {/* </div> */}
        </div>
    </div>
  )
}

export default Trailers