import trailer from './../../images/Trailer-Pics/DSC_0076.jpg'
import trailerTwo from '../../images/Trailer-Pics/DSC_0077.jpg'
import trailerThree from '../../images/Trailer-Pics/DSC_0078.jpg'
import trailerFour from '../../images/Trailer-Pics/DSC_0079.jpg'


export const photos1 = [
    {
      src: trailer,
      width: 3,
      height: 4
    },
    {
      src: trailerThree,
      width: 4,
      height: 3
    },
    {
      src: trailerTwo,
      width: 3,
      height: 4
    },
    {
      src: trailerFour,
      width: 4,
      height: 3
    },
   
  ];
  