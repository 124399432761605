import React from 'react';
import { CDBBox, CDBBtn, CDBIcon } from 'cdbreact';
import Logo from './../images/logo/Logo.png';

const Footer = () => {
  
  const facebookPageURL = 'https://www.facebook.com/sgequipmentrental';
  const tiktokPageURL = 'https://www.tiktok.com/@sgequipment';
  const instagramPageURL = 'https://www.instagram.com/sngequipmentrental3/';

  return (
    <div className="shadow">
      <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{ width: '80%' }}>
        <CDBBox display="flex" justifyContent="between" className="flex-wrap">
          <CDBBox>
            <a href="/" className="d-flex align-items-center p-0 text-dark">
              <img
                alt="logo"
                src={Logo}
                width="30px"
              />
              <span className="ms-3 h5 font-weight-bold">S&G</span>
            </a>
          </CDBBox>
          <CDBBox display="flex" style={{ width: '50%' }} justifyContent="between">
            <CDBBox>
              <p className="h5 mb-4" style={{ fontWeight: '600' }}>
                S&G
              </p>
              <CDBBox flex="column" display="flex" style={{ cursor: 'pointer', padding: '0' }}>
                {/* Add your links here */}
                <a href="tel:778-384-3377">Call Us</a>
                <a href="mailto:sale@sngequipmentrental.com">Email us</a>
              </CDBBox>
            </CDBBox>
            <CDBBox>
              <p className="h5 mb-4" style={{ fontWeight: '600' }}>
                Products
              </p>
              <CDBBox display="flex" flex="column" style={{ cursor: 'pointer', padding: '0' }}>
                {/* Add your product links here */}
                <a href={facebookPageURL}>Facebook</a>
                <a href={instagramPageURL}>Instagram</a>
                <a href={tiktokPageURL}>Tiktok</a>
              </CDBBox>
            </CDBBox>
          </CDBBox>
        </CDBBox>
        <CDBBox display="flex" className="mt-4" justifyContent="between">
          <small className="ms-2">&copy; S&G, 2023. All rights reserved.</small>
          <CDBBox display="flex">
            <a href={facebookPageURL} target="_blank" rel="noopener noreferrer">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="facebook-f" />
              </CDBBtn>
            </a>
            <a href={tiktokPageURL} target="_blank" rel="noopener noreferrer">
              <CDBBtn flat color="dark" className="mx-3 p-2">
                <CDBIcon fab icon="tiktok" />
              </CDBBtn>
            </a>
            <a href={instagramPageURL} target="_blank" rel="noopener noreferrer">
              <CDBBtn flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </a>
          </CDBBox>
        </CDBBox>
      </CDBBox>
    </div>
  );
};

export default Footer;
