import '../Cards/price-card.css'

import React from 'react'

const PriceCard = () => {
  return (
    <div className='price_cards_div'>
        <div className="price_card alpha">
          <div className="header">
        <span className="price">$100</span>
        <span className="name">1 DAY</span>
          </div>
          <ul className="features">
        <li>14 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>

        <div className="price_card bravo">
          <div className="header">
        <span className="price">$280</span>
        <span className="name">3 DAY</span>
          </div>
          <ul className="features">
        <li>14 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>

        <div className="price_card charlie">
          <div className="header">
        <span className="price">$450</span>
        <span className="name">5 DAYS</span>
          </div>
          <ul className="features">
        <li>14 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>
        <div className="price_card bravo">
          <div className="header">
        <span className="price">$550</span>
        <span className="name">7 DAYS</span>
          </div>
          <ul className="features">
        <li>14 ft Dump Trailer</li>
        {/* <li>Working materials in PSD and Sketch format</li> */}
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        {/* </div>
        <div className="price_card alpha">
        <div className="header">
        <span className="price">$500</span>
        <span className="name">5 DAYS</span>
          </div>
          <ul className="features">
        <li>14 ft Dump Traile</li>
        <li>Working materials in PSD and Sketch format</li>
          </ul>
          {/* <button className='pricecard-button'>Add to cart</button> */}
        </div>
    </div>
  )
}

export default PriceCard



