import React, { useState } from 'react';
import moment from 'moment'; // Import the moment library
import {
    Button,
    Form,
    Input,
    Select,
} from 'antd';
import { DatePicker, Space } from 'antd';
import emailjs from 'emailjs-com'; 

import Truckthree from './../images/trailers/pexels-islandhopper-x-17816414.jpg'
import './contact.css'
const { Option } = Select;
 
const formItemLayout = {
    labelCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 16,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};
const App = () => {

    const [form] = Form.useForm();
   
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select
                style={{
                    width: 70,
                }}
            >
                <Option value="1">+1</Option>
            </Select>
        </Form.Item>
    );

    emailjs.init('wc-BOpob8WWypTir0');

 
    const onFinish = (values) => {
        // Format the date using moment.js
        const formattedDate = moment(values.date_picker).format('YYYY-MM-DD');
    
        emailjs.send('service_btcu2zl', 'template_cbxrzys', {
            from_name: values.from_name,
            last_name: values.last_name,
            message: values.description,
            pickup_date: formattedDate, // Use the formatted date
            days: values.days,
            phone_num: values.phone_num,
            email_from: values.email_from,
        })
        .then((response) => {
            console.log('Email sent:', response);
        })
        .catch((error) => {
            console.error('Email error:', error);
        });
    };
    

    const [autoCompleteResult, setAutoCompleteResult] = useState([]);
    const onWebsiteChange = (value) => {
        if (!value) {
            setAutoCompleteResult([]);
        } else {
            setAutoCompleteResult(['.com', '.org', '.net'].map((domain) => `${value}${domain}`));
        }
    };
    const websiteOptions = autoCompleteResult.map((website) => ({
        label: website,
        value: website,
    }));


    const imageStyle = {
        objectFit: 'cover',
        width: '100%',
    };


    const imageStyleTwo = {
        height: '500px',
        objectFit: 'cover',
        width: '100%',
        opacity: 0.8
    };

    return (


        <div>




            <div className='mainContact'>
                <div className='textOverImage'>
                    <img src={Truckthree} style={{ ...imageStyleTwo, ...imageStyle }} alt="" />
                    <div className='centered'>
                        <h2>Contact</h2>

                    </div>
                </div>

                <div className='formDivContainer'>
                    <div className='formDiv'>
                        <Form
                            {...formItemLayout}
                            form={form}
                            name="register"
                            onFinish={onFinish}
                            initialValues={{
                                prefix: '+1',
                            }}
                            style={{
                                maxWidth: 600,
                            }}
                            scrollToFirstError
                        >
                            <Form.Item
                                name="from_name"
                                label="Your Name"
                                rules={[
                                    {
                                        type: 'text',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="last_Name"
                                label="Last Name"
                                rules={[
                                    {
                                        type: 'text',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="email_from"
                                label="Your E-mail"
                                
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid E-mail!',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input your E-mail!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="date_picker"
                                label="Pick a Pickup Date">
                                <DatePicker />
                            </Form.Item>

                            <Form.Item
                                name="days"
                                label="Number Of Days"
                                rules={[
                                    {
                                        type: 'text',
                                    },
                                    {
                                        required: false,
                                        message: 'Please input your days!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="phone_num"
                                label="Phone Number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your phone number!',
                                    },
                                ]}
                            >
                                <Input
                                    addonBefore={prefixSelector}
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label="Your Message(Optional)"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please input Massage',
                                    },
                                ]}
                            >
                                <Input.TextArea showCount maxLength={200} />
                            </Form.Item>


                            <Form.Item {...tailFormItemLayout}>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default App;