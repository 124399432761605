import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Logo from './../images/logo/Screenshot 2566-11-02 at 23.22.11.png';
import './navbar.css';

const NavBar = () => {
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    // Add a class to the body element to enable dark mode when darkMode is true
    if (darkMode) {
      document.body.classList.add('dark-mode-body');
    } else {
      document.body.classList.remove('dark-mode-body');
    }
  }, [darkMode]);

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const navbarClass = darkMode ? 'navbar ml-auto dark-mode' : 'navbar ml-auto';

  return (
    <Navbar className={navbarClass} bg={darkMode ? "dark" : "light"} data-bs-theme={darkMode ? "dark" : "light"}>
      <Container fluid>
        <Navbar.Brand href="#">
          <img className='logo' src={Logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/trailers">Trailers</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            {/* <Nav.Link as={Link} to="#gallery">Gallery</Nav.Link> */}
          </Nav>
          <div className='search-dark'>
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="outline-success">Search</Button>
            </Form>
            <label className="switch">
              <input type="checkbox" onClick={handleDarkModeToggle} />
              <span className="slider"></span>
            </label>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
