import Tridum from '../../images/tridem/DSC_0083.jpg'
import TridumOne from '../../images/tridem/DSC_0084.jpg'
import TridumTwo from '../../images/tridem/DSC_0085.jpg'
import TridumThree from '../../images/tridem/DSC_0093.jpg'

export const photos2 = [
    {
      src: Tridum,
      width: 4,
      height: 3
    },
    {
      src: TridumOne,
      width: 3,
      height: 4
    },
    {
      src: TridumTwo,
      width: 4,
      height: 3
    },
    {
      src: TridumThree,
      width: 3,
      height: 4
    },
   
  ];
  