
import React from 'react'
import Carousel from '../Carousal/Carousel'
import Truckthree from '../../images/trailers/istockphoto-1289928297-612x612.jpg'
import { Link } from 'react-router-dom';
import PriceCard from '../Cards/PriceCard';
import Table from '../Table/Table';
import trailerTwo from '../../images/Trailer-Pics/DSC_0077.jpg'
import trailerThree from '../../images/Trailer-Pics/DSC_0078.jpg'
import trailer from '../../images/Trailer-Pics/DSC_0079.jpg'
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
const SinglePage = () => {
  
const contentStyle = {
  height: '500px',
  lineHeight: '160px',
  textAlign: 'center',

};

const imageStyle = {
  objectFit: 'cover',
  width: '100%',
};

const columnsPage1 = [
    {
      title: 'Measurement',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Inch',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Payload',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  
  const dataPage1 = [
    {
      key: '1',
      name: 'Total Height (rear gate)',
      age: 60,
      address: '15000 Pound',
    },
    {
      key: '2',
      name: 'Box height',
      age: 60,
    },
    {
      key: '3',
      name: 'Total Length',
      age: 204,
    },
    {
      key: '4',
      name: 'Box Length',
      age: 192,
    },
   
  ];

  return (
    <div>
        <h1>Single Page</h1>
        <div>
        <MDBCarousel showIndicators showControls fade>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={1}
          src={trailerThree}
          alt='...'
        >
          <h5>14 ft Dump Trailer</h5>
          
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={2}
          src={trailer}
          alt='...'
        >
          <h5>14 ft Dump Trailer</h5>
          
        </MDBCarouselItem>
        <MDBCarouselItem
          className='w-100 d-block'
          itemId={3}
          src={trailerTwo}
          alt='...'
        >
          <h5>14 ft Dump Trailer</h5>
          
        </MDBCarouselItem>
          </MDBCarousel>



          

          
    </div>

          
        <div className='headerDiv'>

        <div className='home-heading'>
          <p>RENT TRAILERS
          </p>
          <h1>About The Trailer</h1>
          <p>Introducing our 14 ft Dump Trailer with a tandem wheel – the powerhouse you need for heavy-duty hauling! Perfect for landscaping, construction, or any project that demands strength and durability. With its tandem wheel design, this trailer offers enhanced stability and load-bearing capacity. Rent it now and take your project to the next level! Contact us at 778-384-3377 or sgequiprental@gmail.com </p>

          <div className='buttons'>
            <button className='buttonsTwo'>Check Price</button>
          </div>




        </div>

        <div>
          <div className='imageDiv'>
            <img src={Truckthree} style={{ ...contentStyle, ...imageStyle }}></img>
          </div>
        </div>
      </div>

      

      <PriceCard/>

      <br />
      <br />
<hr />
<br />
      <Table columns={columnsPage1} data={dataPage1}/>

      <br />
      <br />



    </div>

    
  )
}

export default SinglePage