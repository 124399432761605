import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavBar from './component/NavBar';
import Contact from './component/Contact';
import Home from './component/Home';
import Trailers from './component/Trailers';
import Footer from './component/Footer';
import SinglePage from './component/SIngle-Page/SinglePage';
import SinglePageTridum from './component/SIngle-Page/SinglePageTridum';
import SinglePageRam from './component/SIngle-Page/SinglePageRam';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/trailers" element={<Trailers />} />
        <Route path="/singlePage" element={<SinglePage />} />
        <Route path="/singlePageTridum" element={<SinglePageTridum />} />
        <Route path="/singlePageRam" element={<SinglePageRam />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
