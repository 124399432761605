import React from 'react';
import { createRoot } from 'react-dom';
import { Table } from 'antd';


const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const TableComponent = ({ columns, data }) => (
  <div style={centerStyle}>
    <Table columns={columns} dataSource={data} style={{ width: '50%' }} />
  </div>
);

export default TableComponent;
