import React, { useState, useCallback } from "react";
import Gallerys from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos1 } from "./photos1";
import { photos2 } from "./photos2";

const Gallery = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const [currentImage2, setCurrentImage2] = useState(0);
  const [viewerIsOpen2, setViewerIsOpen2] = useState(false);

  const openLightbox2 = useCallback((event, { photo, index }) => {
    setCurrentImage2(index);
    setViewerIsOpen2(true);
  }, []);

  const closeLightbox2 = () => {
    setCurrentImage2(0);
    setViewerIsOpen2(false);
  };

  return (
    <>
      {/* FIRST GALLERY */}
      <Gallerys photos={photos1} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos1.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <Gallerys photos={photos2} onClick={openLightbox2} />
      <ModalGateway>
        {viewerIsOpen2 ? (
          <Modal onClose={closeLightbox2}>
            <Carousel
              currentIndex={currentImage2}
              views={photos2.map((x) => ({ // Use photos2 here
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
}

export default Gallery;
